import React, { useEffect, useState } from 'react';
import { useLocalization } from '../../localization';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';

import { WrapperPages } from '../../components/Wrapper/WrapperPages';
import { ExpectedTab } from './components/ExpectedTab/ExpectedTab';
import { OrderTab } from './components/OrderTab/OrderTab';
import { ReturnTab } from './components/ReturnTab/ReturnTab';
import { ReturnsTab } from './components/ReturnsTab/ReturnsTab';
import { WarehouseTab } from './components/WarehouseTab/WarehouseTab';
import { ScanerButton } from './components/ScanerButton';
import { TableChangeButton } from './components/TableChangeButton';
import { ScanModal } from './components/PrintModal/ScanModal';
import { PreDeliveryModal } from './components/OrderTab/components/PreDelivery/PreDeliveryModal';
import { usePreDeliveryStateQuery } from '../../graphql/cacheQuery/__generated__/preDeliveryState';
import { clearDataPreDelivery } from '../../graphql/store/helper/js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getStoredParams, saveParamsToStorage } from '../../utils/localStorage';
import { ProvidersReturnsTab } from './components/ProvidersReturnTab/ProvidersReturnsTab';

const WAREHOUSE_LAST_TAB = 'spWLT';

export const WarehousePage = () => {
  const navigate = useNavigate();
  const { translateLang } = useLocalization();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { data: dataPreDeliveryState } = usePreDeliveryStateQuery();

  const [searchParams] = useSearchParams({
    tab: 'expected',
    ...getStoredParams(WAREHOUSE_LAST_TAB),
  });

  useEffect(() => {
    const tempSearchParams = new URLSearchParams({ tab: searchParams.get('tab') ?? 'expected' });
    saveParamsToStorage(tempSearchParams, WAREHOUSE_LAST_TAB);
  }, [searchParams]);

  const [activeTable, setActiveTable] = useState<string>(searchParams.get('tab') ?? 'expected');

  const handleTabChange = (tabIndex: string) => {
    setActiveTable(tabIndex);
    navigate(`/warehouse?tab=${tabIndex}`, {
      replace: true,
    });
  };

  useEffect(() => {
    return () => {
      clearDataPreDelivery();
    };
  }, []);

  return (
    <>
      <WrapperPages isButton={false} titleHeader={translateLang('warehouse')}>
        <Stack direction="row" justifyContent="space-between" height="48px" marginBottom="30px">
          <TableChangeButton activeTable={activeTable} handleChange={handleTabChange} />
          {activeTable === 'expected' && <ScanerButton handleClick={() => setOpenModal(true)} />}
        </Stack>

        <Stack>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              placeContent: 'center',
            }}
          >
            {activeTable === 'expected' && <ExpectedTab />}
            {activeTable === 'order' && <OrderTab />}
            {activeTable === 'returnFromDelivery' && <ReturnTab />}
            {activeTable === 'returnsFromCustomer' && <ReturnsTab />}
            {activeTable === 'returnsFromProvider' && <ProvidersReturnsTab />}
            {activeTable === 'warehouse' && <WarehouseTab isProviderReturn={false}/>}
          </Box>
        </Stack>
      </WrapperPages>
      {openModal && <ScanModal open={openModal} handleClose={() => setOpenModal(false)} />}
      {dataPreDeliveryState?.preDeliveryState.isOpenModal && (
        <PreDeliveryModal
          open={dataPreDeliveryState?.preDeliveryState.isOpenModal}
          handleClose={() => {
            clearDataPreDelivery();
          }}
        />
      )}
    </>
  );
};
