import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { ReturnType } from './hooks/useGetCustomerReturnsForWarehouseQuery';
import { useCreateCustomerReturnMutation } from '../../../../graphql/mutations/__generated__/createCustomerReturn';
import { CustomerReturnInput, Enum_Customerreturn_Status } from '../../../../__generated__/types';
import { useLocalization } from '../../../../localization';
import { ReturnStatusEditingHistory } from './ToolBarReturnsTab';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { Icon } from '../../../../legos';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useUpdateCustomerReturnMutation } from '../../../../graphql/mutations/__generated__/updateCustomerReturn';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { theme } from '../../../../helpers';
import { useGetClients } from '../../../../graphql/queries/hook/useGetClients';

interface Props {
  open: boolean;
  close: () => void;
  isEdit: boolean;
  item?: ReturnType;
}

export const CreateUpdateReturnModal = ({ open, close, isEdit, item }: Props) => {
  const [updateCustomerReturnMutation] = useUpdateCustomerReturnMutation();
  const [providerStatus, setProviderStatus] = useState('');
  const [error, setError] = useState(false);
  const [createCustomerReturnMutation] = useCreateCustomerReturnMutation();
  const { translateLang } = useLocalization();

  const { clients } = useGetClients({
    pagination: {
      limit: -1,
    },
  });
  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, handleBlur } =
    useFormik({
      initialValues: {
        customer: isEdit ? item?.id : '',
        status: isEdit ? item?.status : Enum_Customerreturn_Status.Created,
        dateReturned: isEdit ? item?.dateReturned : '',
        correctiveInvoiceDate: isEdit ? item?.correctiveInvoiceDate : '',
        correctiveInvoiceNumber: isEdit ? item?.correctiveInvoiceNumber : '',
      },
      validationSchema: Yup.object({
        customer: Yup.string().required(translateLang('Customer is required')),
      }),
      onSubmit: async (values: CustomerReturnInput) => {
        if (!values.customer) {
          setError(true);
        } else {
          setError(false);
        }
        try {
          isEdit
            ? await updateCustomerReturnMutation({
                variables: {
                  id: values?.customer ?? '',
                  data: values,
                },
              })
            : await createCustomerReturnMutation({
                variables: {
                  data: values,
                },
              });
          close();
        } catch (err) {
          console.error(err);
        }
      },
    });
  const WareHouseReturnStatus: ReturnStatusEditingHistory[] = [
    ...[Enum_Customerreturn_Status.Created, Enum_Customerreturn_Status.Completed].map(value => ({
      label: value,
      value: value,
    })),
  ];
  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Stack bgcolor={theme.palette.common.lightGrey} sx={{ boxShadow: 24, borderRadius: 2 }}>
          <form onSubmit={handleSubmit}>
            <DialogTitle id="scroll-dialog-title" sx={{}}>
              <IconButton onClick={close} sx={{ p: 0, display: 'flex', justifySelf: 'end' }}>
                <Icon icon="close" />
              </IconButton>
              <Typography
                variant="h4"
                fontSize={30}
                sx={{ display: 'flex', justifySelf: 'center' }}
              >
                {isEdit ? translateLang('editReturn') : translateLang('createReturn')}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Stack
                justifyContent="space-between"
                flexDirection="row"
                alignItems="start"
                gap={3}
                mb={6}
              >
                <TranslatedField
                  originText={'client'}
                  fontSize={16}
                  isTranslate
                  noWrap
                  overflow="initial"
                />
                <Autocomplete
                  disablePortal
                  size="small"
                  disabled={isEdit}
                  options={clients}
                  value={clients.find(item => item.id === values.customer) || null}
                  getOptionLabel={option => option.fullName ?? ''}
                  sx={{
                    width: 400,
                    textAlign: 'center',
                    paddingLeft: 0,
                    boxShadow: ' 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset',
                    height: 12,
                    background: theme.palette.common.white,
                  }}
                  renderInput={params => (
                    <TextField
                      sx={{ background: theme.palette.common.white }}
                      error={touched.customer && !!errors.customer}
                      helperText={touched.customer && errors.customer}
                      FormHelperTextProps={{
                        sx: { margin: 0, background: theme.palette.common.lightGrey },
                      }}
                      {...params}
                    />
                  )}
                  onChange={(evt, option) => setFieldValue('customer', option ? option.id : '')}
                />
              </Stack>
              <Box sx={{ mt: '30px' }}>
                <Stack
                  justifyContent="space-between"
                  flexDirection="row"
                  alignItems="baseline"
                  gap={3}
                  mb={6}
                >
                  <TranslatedField
                    originText={'returnDate'}
                    fontSize={16}
                    isTranslate
                    noWrap
                    overflow="initial"
                  />
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="dd.MM.yyyy"
                      value={values.dateReturned ? values.dateReturned : null}
                      onChange={(newValue: Date | null) => {
                        const formattedDate = newValue ? format(newValue, 'yyyy-MM-dd') : '';
                        setFieldValue('dateReturned', formattedDate);
                      }}
                      renderInput={params => (
                        <TextField
                          sx={{ width: 400, background: theme.palette.common.white }}
                          FormHelperTextProps={{
                            sx: { margin: 0, background: theme.palette.common.lightGrey },
                          }}
                          size="small"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
              </Box>
              <Box>
                <Stack
                  justifyContent="space-between"
                  flexDirection="row"
                  alignItems="baseline"
                  gap={3}
                  mb={6}
                >
                  <TranslatedField
                    originText={'correctiveInvoiceDate'}
                    fontSize={16}
                    isTranslate
                    noWrap
                    overflow="initial"
                  />
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="dd.MM.yyyy"
                      value={values.correctiveInvoiceDate ? values.correctiveInvoiceDate : null}
                      onChange={(newValue: Date | null) => {
                        const formattedDate = newValue ? format(newValue, 'yyyy-MM-dd') : null;
                        setFieldValue('correctiveInvoiceDate', formattedDate);
                      }}
                      renderInput={params => (
                        <TextField
                          sx={{ width: 400, background: theme.palette.common.white }}
                          size="small"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
              </Box>
              <Stack
                justifyContent="space-between"
                flexDirection="row"
                alignItems="baseline"
                gap={3}
                mb={6}
              >
                <TranslatedField
                  originText={'correctiveInvoiceNumber'}
                  fontSize={16}
                  isTranslate
                  noWrap
                  overflow="initial"
                />
                <TextField
                  size="small"
                  sx={{ width: 400, background: theme.palette.common.white }}
                  variant="outlined"
                  defaultValue={values.correctiveInvoiceNumber}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('correctiveInvoiceNumber', event.target.value ?? '')
                  }
                />
              </Stack>
              <Stack justifyContent="space-between" flexDirection="row" alignItems="center" gap={3}>
                <TranslatedField
                  originText={'status'}
                  fontSize={16}
                  isTranslate
                  noWrap
                  overflow="initial"
                />
                <Autocomplete
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  sx={{ width: 400 }}
                  value={
                    WareHouseReturnStatus.find(option => option.value == values?.status) || null
                  }
                  options={WareHouseReturnStatus}
                  disabled={item?.status == Enum_Customerreturn_Status.Created ? false : true}
                  renderInput={params => (
                    <TextField {...params} sx={{ background: theme.palette.common.white }} />
                  )}
                  onChange={(event, selectedOption) => {
                    if (selectedOption) {
                      setFieldValue('status', selectedOption.value);
                    }
                  }}
                  getOptionLabel={option => {
                    return translateLang(option.label) || '';
                  }}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.label}>
                      {translateLang(option.value) || ''}
                    </Box>
                  )}
                />
              </Stack>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  height: 50,
                  m: '20px',
                  p: 2,
                  textTransform: 'none',
                  backgroundColor: '#5269A3',
                  borderRadius: '10px',
                  '&:disabled': {
                    color: theme.palette.common.white,
                  },
                }}
              >
                {translateLang('save')}
              </Button>
            </DialogActions>
          </form>
        </Stack>
      </Dialog>
    </>
  );
};
