/* eslint-disable no-console */
import React, { FC } from 'react';
import { Box } from '@mui/material';
import { ItemWarehouseType } from '../../../../../graphql/queries/hook/useGetWarehouse';
import { useGetWarehouseTabTable } from '../hooks/useGetWarehouseTabTable';
import { TableProductActive } from '../../../../../components/TableProductActive/TableProductActive';
import { ProviderReturnsItemsType } from '../../ProvidersReturnTab/ProviderReturnedItemsPage';
interface Props {
  totalPage: number;
  itemsWarehouse?: ItemWarehouseType[];
  currentPage?: number;
  changePage(page: number): void;
  loading?: boolean;
  sort: string[];
  sortHandler: (field: string) => void;
  isProviderReturn: boolean;
  returnId?: string;
  providerReturnsItems?: ProviderReturnsItemsType;
  refetchProviderReturnItems?: () => void;
}

export const WarhouseOrderTabTable: FC<Props> = ({
  itemsWarehouse,
  totalPage,
  currentPage,
  changePage,
  loading,
  sort,
  sortHandler,
  isProviderReturn,
  returnId,
  providerReturnsItems,
  refetchProviderReturnItems,
}) => {
  const { getHeadersTable, getRowsTableActive } = useGetWarehouseTabTable({
    itemsWarehouse,
    sort,
    isProviderReturn,
    returnId: returnId ?? '',
    providerReturnsItems: providerReturnsItems,
    refetchProviderReturnItems: refetchProviderReturnItems,
  });
  return (
    <>
      <Box boxSizing="border-box" width="100%">
        <TableProductActive
          startPage={currentPage}
          totalPages={totalPage}
          itemsRows={getRowsTableActive()}
          itemsHeader={getHeadersTable()}
          changeCurrentPage={changePage}
          loading={loading}
          sortHandler={sortHandler}
        />
      </Box>
    </>
  );
};
