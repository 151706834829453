import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';

import { SupplierReturnFiltersInput, InputMaybe } from '../../../../../__generated__/types';
import { getStoredParams, saveParamsToStorage } from '../../../../../utils/localStorage';
import { parse } from 'date-fns';
import { getProperPage } from '../../../../../utils/getProperPage';
export type WarehouseReturnsFromProvidersTabSearchParams = {
  searchText?: string;
  page?: string;
  dateFrom?: string;
  dateTo?: string;
  status: string;
};

type DefaultWarehouseReturnsFromProviderTabSearchParams = {
  defaultDateFrom?: string;
  defaultDateTo?: string;
};

type SetParam = Partial<
  Record<keyof WarehouseReturnsFromProvidersTabSearchParams, string | string[]>
>;

const WAREHOUSE_RETURNS_FROM_PROVIDER_TAB_LOCAL_STORAGE_KEY = 'spWRFPTSP';

export const useWarehouseReturnsFromProviderTabSearchParams = ({
  defaultDateFrom,
  defaultDateTo,
}: DefaultWarehouseReturnsFromProviderTabSearchParams = {}) => {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams({
    tab: 'returnsFromProvider',
    ...(defaultDateFrom ? { dateFrom: defaultDateFrom } : null),
    ...(defaultDateTo ? { dateTo: defaultDateTo } : null),
    ...getStoredParams(WAREHOUSE_RETURNS_FROM_PROVIDER_TAB_LOCAL_STORAGE_KEY),
  });

  const dateTo = urlSearchParams.get('dateTo');
  const page = getProperPage(urlSearchParams.get('page') ?? '1');
  const dateFrom = urlSearchParams.get('dateFrom');
  const status = urlSearchParams.get('status') ?? '';
  const searchText = urlSearchParams.get('searchText') ?? '';

  useEffect(() => {
    setUrlSearchParams(oldSearchParams => {
      if (oldSearchParams.get('dateFrom')) {
        oldSearchParams.set('dateFrom', oldSearchParams.get('dateFrom') as string);
      } else {
        oldSearchParams.delete('dateFrom');
      }

      if (oldSearchParams.get('dateTo')) {
        oldSearchParams.set('dateTo', oldSearchParams.get('dateTo') as string);
      } else {
        oldSearchParams.delete('dateTo');
      }

      if (oldSearchParams.get('page')) {
        oldSearchParams.set('page', getProperPage(oldSearchParams.get('page') ?? '1'));
      }

      oldSearchParams.set('tab', 'returnsFromProvider');

      return oldSearchParams;
    });
  }, []);

  useEffect(() => {
    setUrlSearchParams(currentUrlSearchParams => {
      const tempUrlSearchParams = new URLSearchParams();
      if (currentUrlSearchParams.get('searchText')) {
        tempUrlSearchParams.set('searchText', currentUrlSearchParams.get('searchText') as string);
      }
      if (currentUrlSearchParams.get('status')) {
        tempUrlSearchParams.set('status', currentUrlSearchParams.get('status') as string);
      }
      if (currentUrlSearchParams.get('dateFrom')) {
        tempUrlSearchParams.set('dateFrom', currentUrlSearchParams.get('dateFrom') as string);
      }
      if (currentUrlSearchParams.get('dateTo')) {
        tempUrlSearchParams.set('dateTo', currentUrlSearchParams.get('dateTo') as string);
      }
      if (urlSearchParams.get('page')) {
        tempUrlSearchParams.set(
          'page',
          getProperPage(urlSearchParams.get('page') ?? '1') as string
        );
      }

      saveParamsToStorage(
        tempUrlSearchParams,
        WAREHOUSE_RETURNS_FROM_PROVIDER_TAB_LOCAL_STORAGE_KEY
      );
      return currentUrlSearchParams;
    });
  }, [setUrlSearchParams]);

  const changeSearchText = useCallback(
    (text: string | undefined) => {
      if (text !== searchText) {
        setUrlSearchParams(oldSearchParams => {
          oldSearchParams.set('page', '1');

          if (text) {
            oldSearchParams.set('searchText', text);
          } else {
            oldSearchParams.delete('searchText');
          }

          oldSearchParams.set('tab', 'returnsFromProvider');

          return oldSearchParams;
        });
      }
    },
    [searchText, setUrlSearchParams]
  );

  const changeParam = useCallback(
    (params: SetParam) => {
      setUrlSearchParams(oldSearchParams => {
        let isPageParamChanged = false;
        Object.keys(params).forEach(key => {
          if (!isPageParamChanged && key === 'page') {
            isPageParamChanged = true;
          }
          const value = params[key as keyof WarehouseReturnsFromProvidersTabSearchParams];
          if (!value || (Array.isArray(value) && value.length === 0)) {
            oldSearchParams.delete(key);
          } else if (Array.isArray(value)) {
            oldSearchParams.delete(key);
            value.forEach(v => oldSearchParams.append(key, v));
          } else {
            oldSearchParams.set(key, value);
          }
        });

        if (!isPageParamChanged) {
          oldSearchParams.set('page', '1');
        }
        oldSearchParams.set('tab', 'returnsFromProvider');

        return oldSearchParams;
      });
    },
    [setUrlSearchParams]
  );

  const filters = useMemo(() => {
    const newFilters: InputMaybe<SupplierReturnFiltersInput> = {
      and: [],
    };

    if (searchText) {
      newFilters.and?.push({
        or: [
          { id: { containsi: searchText } },
          {
            supplier: {
              name: { containsi: searchText },
            },
          },
          { correctiveInvoiceNumber: { containsi: searchText } },
        ],
      });
    }

    if (status) {
      newFilters.and?.push({
        status: { eq: status },
      });
    }

    if (dateFrom && dateTo) {
      const dateFromParsed = parse(dateFrom, 'yyyy-MM-dd', new Date());
      const dateToParsed = parse(dateTo, 'yyyy-MM-dd', new Date());

      if (!isNaN(dateFromParsed.getTime()) && !isNaN(dateToParsed.getTime())) {
        newFilters.and?.push({
          dateReturned: { between: [dateFrom, dateTo] },
        });
      }
    }

    return newFilters;
  }, [searchText, dateFrom, dateTo, status]);

  const selectedDates = useMemo(() => {
    const datesRange = [];

    if (dateFrom) {
      const dateFromParsed = parse(dateFrom ?? '', 'yyyy-MM-dd', new Date());
      if (!isNaN(dateFromParsed.getTime())) {
        datesRange.push(dateFromParsed);
        if (dateTo) {
          const dateToParsed = parse(dateTo ?? '', 'yyyy-MM-dd', new Date());

          if (!isNaN(dateToParsed.getTime())) {
            datesRange.push(dateToParsed);
          }
        }
      }
    }

    return datesRange;
  }, [dateFrom, dateTo]);

  const resetFilters = useCallback(() => {
    setUrlSearchParams(() => {
      const newSearchParams = new URLSearchParams();
      if (defaultDateFrom) {
        newSearchParams.set('dateFrom', defaultDateFrom);
      }
      if (defaultDateTo) {
        newSearchParams.set('dateTo', defaultDateTo);
      }
      newSearchParams.set('page', '1');
      newSearchParams.set('tab', 'returnsFromProvider');

      return newSearchParams;
    });
  }, [defaultDateFrom, defaultDateTo, setUrlSearchParams]);

  return {
    changeParam,
    changeSearchText,
    searchText,
    page,
    dateFrom,
    dateTo,
    filters,
    selectedDates,
    status,
    resetFilters,
  };
};
