import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useGetReturnedItemsTable } from './hooks/useGetProvidersReturnedItemsTable';
import { TableProductActive } from '../../../../components/TableProductActive/TableProductActive';
import { ReturnType } from './hooks/useGetProvidersReturns';

interface Props {
  returnedItems: Array<ReturnType>;
  returnedItemsNotPaginated?: Array<ReturnType>;
  loading?: boolean;
  totalPage?: number;
  currentPage?: number;
  changePage?: (page: number) => void;
}

export const ProvidersReturnsTabTable: FC<Props> = ({
  returnedItems,
  loading,
  currentPage,
  totalPage,
  changePage,
}) => {
  const { getHeadersTable, getRowsTableActive } = useGetReturnedItemsTable({
    returnedItems,
  });
  return (
    <Box boxSizing="border-box" width="100%">
      <TableProductActive
        startPage={currentPage}
        totalPages={totalPage}
        itemsRows={getRowsTableActive()}
        itemsHeader={getHeadersTable()}
        changeCurrentPage={changePage}
        loading={loading}
      />
    </Box>
  );
};
