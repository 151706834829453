import React from 'react';
import { GetStocksQueryVariables, useGetStocksQuery } from '../__generated__/getStocks';
import { useLocalization } from '../../../localization';

export type CellAddressType = {
  id: string;
  address: string;
};

export interface ItemWarehouseType {
  article: string;
  supplier: string;
  productName: string;
  brandName: string;
  stockAddressOrder: Array<CellAddressType>;
  productStockId: string;
  balanceQuantity: number;
  incomingQuantity: number;
  reservedQuantity: number;
  supplierPrice: number;
}
interface ReturnHook {
  itemsWarehouse: Array<ItemWarehouseType>;
  loading: boolean;
  totalPage: number;
  pageSize: number;
  refetch: () => void;
}

interface PropsHook {
  filters?: GetStocksQueryVariables['filters'];
  page: number;
  pageSize: number;
  sort?: string[];
}
export const useGetWarehouse = ({ filters, page, pageSize, sort }: PropsHook): ReturnHook => {
  const { updateSelectLanguage } = useLocalization();

  const { data, loading, refetch } = useGetStocksQuery({
    variables: {
      sort,
      pagination: {
        page,
        pageSize,
      },
      filtersLocale: { locale: { eqi: updateSelectLanguage } },
      filters,
    },
  });

  const itemsWarehouse: Array<ItemWarehouseType> = [];
  if (data?.stocks?.data.length) {
    data.stocks.data.forEach(item => {
      const stockAddressOrder: CellAddressType[] = [];
      if (item.attributes?.stock_cell_address?.data?.attributes) {
        const a = item.attributes?.stock_cell_address?.data?.attributes?.shelf || '';
        const b = item.attributes?.stock_cell_address?.data?.attributes?.cell || '';
        stockAddressOrder.push({
          id: item.attributes?.stock_cell_address?.data?.id || '',
          address: `${a}/${b}`,
        });
      }

      itemsWarehouse.push({
        productStockId: item.id || '',
        balanceQuantity: item?.attributes?.balanceQuantity || 0,
        reservedQuantity: item?.attributes?.reservedQuantity || 0,
        incomingQuantity: item?.attributes?.incomingQuantity || 0,
        supplier: item.attributes?.supplier?.data?.attributes?.name || '',
        supplierPrice: item.attributes?.supplierPrice || 0,
        article: item.attributes?.car_spare?.data?.attributes?.tecdoc_articleNumber || '',
        brandName:
          item.attributes?.car_spare?.data?.attributes?.car_spare_locales?.data[0].attributes
            ?.brandName || '',
        productName:
          item.attributes?.car_spare?.data?.attributes?.car_spare_locales?.data[0].attributes
            ?.title || '',
        stockAddressOrder,
      });
    });
  }

  return {
    itemsWarehouse,
    loading,
    pageSize: data?.stocks?.meta.pagination.total || 0,
    totalPage: data?.stocks?.meta.pagination.pageCount || 0,
    refetch,
  };
};
