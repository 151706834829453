import React, { Dispatch, FC, SetStateAction } from 'react';
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { Icon } from '../../../legos';
import {
  AdminPanelSettings,
  ArrowForwardIosRounded,
  ChatBubbleOutlineRounded,
  CollectionsOutlined,
  GroupOutlined,
  LocalShippingOutlined,
  ProductionQuantityLimitsRounded,
  ShoppingCartOutlined,
  Warehouse,
  ManageAccounts,
  AccountBalance,
} from '@mui/icons-material';

import { Drawer as DrawerCustom } from '../helpers/styledComponents';
import { useAuthUser } from '../../../hooks';
import { useLocalization } from '../../../localization';
import { PatchesName, RolesEnum, RoutesPathsType } from '../../../types/types';
import { useGetMe } from '../../../graphql/queries/hook/useGetMe';
import { VERSION_APP } from '../../../helpers/constants';

interface Props {
  open: boolean;
  path: string;
  handleDrawerOpen: Dispatch<SetStateAction<boolean>>;
}
export const RoutesPaths: Array<RoutesPathsType> = [
  {
    title: PatchesName.Orders,
    icon: <ShoppingCartOutlined />,
    role: [
      RolesEnum.SuperAdmin,
      RolesEnum.Admin,
      RolesEnum.WarehouseManager,
      RolesEnum.Manager,
      RolesEnum.Accounter,
    ],
    // Component: lazy(() => import('../../../pages/OrdersList')),
  },
  {
    title: PatchesName.Clients,
    icon: <GroupOutlined />,
    urlParams: '/:idUser',
    role: [
      RolesEnum.SuperAdmin,
      RolesEnum.Admin,
      RolesEnum.WarehouseManager,
      RolesEnum.Manager,
      RolesEnum.Accounter,
    ],
    // Component: lazy(() => import('../../../pages/ClientInfo')),
  },
  {
    title: PatchesName.Orders,
    urlParams: '/:idUser/:idOrder',
    role: [
      RolesEnum.SuperAdmin,
      RolesEnum.Admin,
      RolesEnum.WarehouseManager,
      RolesEnum.Manager,
      RolesEnum.Accounter,
    ],
    // Component: lazy(() => import('../../../pages/OrderInfo')),
  },
  {
    title: PatchesName.Orders,
    subTitle: PatchesName.CreateOrder,
    role: [
      RolesEnum.SuperAdmin,
      RolesEnum.Admin,
      RolesEnum.WarehouseManager,
      RolesEnum.Manager,
      RolesEnum.Accounter,
    ],
    // Component: lazy(() => import('../../../pages/CreateOrderPage')),
  },
  {
    title: PatchesName.Orders,
    subTitle: PatchesName.CreateOrder,
    urlParams: '/:idUser',
    role: [RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.Manager, RolesEnum.Accounter],
    // Component: lazy(() => import('../../../pages/CheckoutPage')),
  },
  {
    title: PatchesName.Orders,
    subTitle: PatchesName.CreateOrder,
    urlParams: '/:idUser/:idOrder',
    role: [
      RolesEnum.SuperAdmin,
      RolesEnum.Admin,
      RolesEnum.WarehouseManager,
      RolesEnum.Manager,
      RolesEnum.Accounter,
    ],
    // Component: lazy(() => import('../../../pages/CheckoutPage')),
  },
  {
    title: PatchesName.Warehouse,
    icon: <Warehouse />,
    role: [RolesEnum.SuperAdmin, RolesEnum.WarehouseManager, RolesEnum.Admin],
    // Component: lazy(() => import('../../../pages/Warehouse')),
  },
  {
    title: PatchesName.Delivery,
    icon: <LocalShippingOutlined />,
    role: [RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.Courier, RolesEnum.WarehouseManager],
    // Component: lazy(() => import('../../../pages/Warehouse')),
  },
  {
    title: PatchesName.Feedback,
    icon: <ChatBubbleOutlineRounded />,
    role: [RolesEnum.SuperAdmin, RolesEnum.Manager, RolesEnum.Admin, RolesEnum.WarehouseManager],
    // Component: lazy(() => import('../../../pages/FeedbackPage')),
  },

  {
    title: PatchesName.TopProducts,
    icon: <ProductionQuantityLimitsRounded />,
    role: [RolesEnum.SuperAdmin, RolesEnum.Manager, RolesEnum.Admin],
    // Component: lazy(() => import('../../../pages/TopProduts')),
  },
  {
    title: PatchesName.TopProducts,
    urlParams: '/:idCategory',
    role: [RolesEnum.SuperAdmin, RolesEnum.Manager, RolesEnum.Admin],
    // Component: lazy(() => import('../../../pages/AddTopProduts')),
  },
  {
    title: PatchesName.Banner,
    icon: <CollectionsOutlined />,
    role: [RolesEnum.SuperAdmin, RolesEnum.Manager, RolesEnum.Admin],
    // Component: lazy(() => import('../../../pages/AddBanner')),
  },
  {
    title: PatchesName.AdminSettings,
    icon: <AdminPanelSettings />,
    role: [RolesEnum.SuperAdmin, RolesEnum.Admin],
    // Component: lazy(() => import('../../../pages/ChangeCatalog')),
  },
  {
    title: PatchesName.ManagerPanel,
    icon: <ManageAccounts />,
    role: [RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.Manager, RolesEnum.WarehouseManager],
  },
  {
    title: PatchesName.Accounting,
    icon: <AccountBalance />,
    role: [RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.Accounter],
  },
];

export const Drawer: FC<Props> = ({ open, path, handleDrawerOpen }) => {
  const { role } = useGetMe();
  const { translateLang } = useLocalization();
  const { logout } = useAuthUser();

  return (
    <DrawerCustom
      variant="permanent"
      open={open}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          overflowY: 'visible',
        },
      }}
    >
      <Box width="100%" overflow="hidden">
        <List>
          {RoutesPaths.filter(item => item.role.includes(role) && item?.icon).map(item => (
            <Link key={item.title} to={`/${item.title}`} style={{ textDecoration: 'none' }}>
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  // onClick={handleDrawerOpen}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      color: 'white',
                      border: '1px solid #F1F3F8',
                      borderRadius: 2,
                      padding: 1,
                      // backgroundColor: path === item.path ? 'rgba(241,243,248,0.39)' : 'transparent',
                      backgroundColor: path.includes(item.title)
                        ? 'rgba(241,243,248,0.39)'
                        : 'transparent',
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={translateLang(item.title)}
                    sx={{
                      color: 'white',
                      opacity: open ? 1 : 0,
                      textTransform: 'uppercase',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
      </Box>

      <Box
        flex={1}
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        paddingBottom={2}
      >
        <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            onClick={() => logout()}
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
                color: 'white',
                padding: 1,
              }}
            >
              <Icon icon="logout" />
            </ListItemIcon>
            <ListItemText
              primary={translateLang('logout')}
              sx={{ color: 'white', opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
        <Typography fontSize="12px" color="#e0e0e0">{`v: ${VERSION_APP}`}</Typography>
      </Box>
      <Box
        position="absolute"
        width="40px"
        height="40px"
        bgcolor="#2A3453"
        borderRadius="20px"
        top={`calc(50% - 20px)`}
        right="-19px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <IconButton
          onClick={() => handleDrawerOpen(!open)}
          color="inherit"
          sx={{
            '&': {
              transform: `rotate(${open ? 180 : 0}deg)`,
              border: '1px solid #fff',
            },
          }}
        >
          <ArrowForwardIosRounded sx={{ color: '#fff', fontSize: '14px' }} />
        </IconButton>
      </Box>
    </DrawerCustom>
  );
};
