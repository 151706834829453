import React, { FC, ReactNode, useState } from 'react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { CustomModal } from '../../../components';
import { useLocalization } from '../../../localization';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { useHandlerNotificationApp } from '../../../hooks/useHandlerNotificationApp';
import { handlerError } from '../../../helpers/functions';
import { GetOrderByIdDocument } from '../../../graphql/queries/__generated__/getOrderById';
import { useUpdateOrderPricesMutation } from '../../../graphql/mutations/__generated__/updateOrderPrices';
import { GetOrderItemsDocument } from '../../../graphql/queries/__generated__/getOrderItems';
import { OrderItemType } from '../../../helpers/types';

interface Props {
  orderItems: OrderItemType[];
  orderId?: string;
  handleClose: () => void;
  open: boolean;
}

export const ChangePriceForAllOrderItemsModal: FC<Props> = ({
  orderId,
  handleClose,
  open,
  orderItems,
}) => {
  const { translateLang, selectLanguages, updateSelectLanguage } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();

  const markUpSum = (): string => {
    const { totalCustomerCost, totalProviderCost } = orderItems.reduce(
      (acc, item) => {
        const customerPrice = item?.customerPrice ?? 0;
        const providerPrice = item?.providerPrice ?? 0;
        const quantity = item?.quantityAfterRemovingAsReturn ?? 0;

        acc.totalCustomerCost += customerPrice * quantity;
        acc.totalProviderCost += providerPrice * quantity;

        return acc;
      },
      { totalCustomerCost: 0, totalProviderCost: 0 }
    );
    const ratio = totalProviderCost ? totalCustomerCost / totalProviderCost : 0;

    return ratio.toFixed(1);
  };

  const [runUpdateOrderPrices, { loading }] = useUpdateOrderPricesMutation({
    refetchQueries: [
      {
        query: GetOrderByIdDocument,
        variables: {
          id: orderId,
          locale: updateSelectLanguage,
        },
      },
      {
        query: GetOrderItemsDocument,
        variables: {
          filters: orderId ? { order: { id: { eq: orderId } } } : {},
          pagination: { limit: -1 },
          filtersLocale: { locale: { eqi: updateSelectLanguage } },
          sort: ['id'],
        },
      },
    ],
  });
  const [markup, setMarkup] = useState(markUpSum());

  const handleSetNewMarkupForOrder = async () => {
    try {
      const parsedMarkup = parseFloat(markup);
      if (orderId && !Number.isNaN(parsedMarkup)) {
        const response = await runUpdateOrderPrices({
          variables: {
            id: orderId,
            markup: parseFloat(markup),
            locale: selectLanguages as string,
          },
        });
        if (response.data) {
          handleClose();
          addNotification({
            messageError: 'Order total is changed',
            typeMessage: 'success',
          });
        }
      }
    } catch (error: unknown) {
      handlerError(error);
    }
  };

  return (
    <CustomModal
      title={translateLang('markup')}
      handleClose={handleClose}
      open={open}
      bgcolor="#F1F3F8"
      width="460px"
      display="flex"
      flexDirection="column"
    >
      <Stack width="100%" height="100%" p="10px 20px" boxSizing="border-box">
        <InformItem
          label={translateLang('markupValue')}
          value={
            <TextField
              autoComplete="off"
              value={markup}
              onChange={e => setMarkup(e.target.value)}
              type="number"
            />
          }
        />
        <Button
          onClick={handleSetNewMarkupForOrder}
          variant="contained"
          disabled={loading}
          style={{
            textTransform: 'none',
            borderRadius: '8px',
            height: '50px',
            marginTop: '20px',
          }}
        >
          <TranslatedField originText="save" fontSize={16} isTranslate noWrap />
        </Button>
      </Stack>
    </CustomModal>
  );
};

interface PropsInformItem {
  label: string;
  value: string | ReactNode;
}
export function InformItem({ label, value }: PropsInformItem) {
  return (
    <Box display="flex" width="100%" alignItems="center" mb={2} justifyContent="space-between">
      <Typography mr={3} fontSize="20px">{`${label}: `}</Typography>
      {typeof value === 'string' ? <Typography fontSize="20px">{`${value}`}</Typography> : value}
    </Box>
  );
}
