import React, { FC } from 'react';
import * as yup from 'yup';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { ArrowBack } from '@mui/icons-material';
import { Button, CircularProgress, IconButton, Stack, TextField, Typography } from '@mui/material';

import { theme } from '../../../../helpers';
import { CalendarMUI, Icon, Input } from '../../../../legos';
import { useLocalization } from '../../../../localization';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useCreateCustomerPaymentMutation } from '../../../../graphql/mutations/__generated__/createCustomerPayment';
import {
  Enum_Customerpayment_Paymentdirection,
  Enum_Customerpayment_Paymentmethod,
} from '../../../../__generated__/types';
import { PaymentFormFields } from './type';
import { useHandlerNotificationApp } from '../../../../hooks/useHandlerNotificationApp';
import { GetOrderByIdDocument } from '../../../../graphql/queries/__generated__/getOrderById';
import { useCreateOrderPaymentMutation } from '../../../../graphql/mutations/__generated__/createOrderPayment';
import { formatCurrencyAmount } from '../../../../helpers/functions';
import { useSetSelectLanguageUserQuery } from '../../../../graphql/store/__generated__/setSelectLanguageUser';

interface PaymentFormProps {
  handleClosePaymentForm: () => void;
  handleClose: () => void;
  payType: 'cash' | 'bankTransfer' | 'PA' | '';
  customerAmount?: null | number;
  customerId: string;
  orderId: string;
  refetchCustomerAmounts: () => void;
}

const initialValues = {
  [PaymentFormFields.Amount]: '',
  [PaymentFormFields.Description]: '',
  [PaymentFormFields.Date]: new Date(),
};

export const PaymentForm: FC<PaymentFormProps> = ({
  handleClosePaymentForm,
  handleClose,
  payType,
  customerAmount,
  customerId,
  orderId,
  refetchCustomerAmounts,
}) => {
  const { translateLang, selectLanguages } = useLocalization();
  const [createCustomerPaymentMutation, { loading: customerPaymentLoading }] =
    useCreateCustomerPaymentMutation();
  const [createOrderPaymentMutation, { loading: customerOrderLoading }] =
    useCreateOrderPaymentMutation();
  const { addNotification } = useHandlerNotificationApp();

  const validationSchema = yup.object({
    [PaymentFormFields.Amount]: yup
      .number()
      .typeError(translateLang('mustBeNumber'))
      .required(translateLang('emptyInputError')),
  });

  const {
    values,
    errors,
    touched,
    dirty,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldError,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ amount, description, date }) => {
      const currentDate = format(new Date(), 'yyyy-MM-dd');
      if (payType === 'cash') {
        createCustomerPaymentMutation({
          variables: {
            data: {
              total: +amount,
              customer: customerId,
              orderId: orderId,
              paymentDate: format(date, 'yyyy-MM-dd') || currentDate,
              paymentDirection: Enum_Customerpayment_Paymentdirection.Incoming,
              paymentMethod: Enum_Customerpayment_Paymentmethod.Cash,
              comment: description,
            },
          },
          refetchQueries: [
            {
              query: GetOrderByIdDocument,
              variables: {
                id: orderId,
                locale: selectLanguages,
              },
            },
          ],
        })
          .then(() => {
            addNotification({
              messageError: translateLang('paymentWasSuccessful'),
              typeMessage: 'success',
            });
            refetchCustomerAmounts();
            handleClose();
          })
          .catch(err => {
            addNotification({
              messageError: err?.message,
              typeMessage: 'error',
            });
          });
      }
      if (payType === 'bankTransfer') {
        createCustomerPaymentMutation({
          variables: {
            data: {
              total: +amount,
              customer: customerId,
              orderId: orderId,
              paymentDate: format(date, 'yyyy-MM-dd') || currentDate,
              paymentDirection: Enum_Customerpayment_Paymentdirection.Incoming,
              paymentMethod: Enum_Customerpayment_Paymentmethod.NonCash,
              comment: description,
            },
          },
          refetchQueries: [
            {
              query: GetOrderByIdDocument,
              variables: {
                id: orderId,
                locale: selectLanguages,
              },
            },
          ],
        })
          .then(() => {
            addNotification({
              messageError: translateLang('paymentWasSuccessful'),
              typeMessage: 'success',
            });
            refetchCustomerAmounts();
            handleClose();
          })
          .catch(err => {
            addNotification({
              messageError: err?.message,
              typeMessage: 'error',
            });
          });
      }
      if (payType === 'PA') {
        createOrderPaymentMutation({
          variables: {
            orderId: orderId,
            paymentAmount: +amount,
          },
          refetchQueries: [
            {
              query: GetOrderByIdDocument,
              variables: {
                id: orderId,
                locale: selectLanguages,
              },
            },
          ],
        })
          .then(() => {
            addNotification({
              messageError: translateLang('withdrawalWasSuccessful'),
              typeMessage: 'success',
            });
            refetchCustomerAmounts();
            handleClose();
          })
          .catch(err => {
            addNotification({
              messageError: err?.message,
              typeMessage: 'error',
            });
            setFieldError(
              PaymentFormFields.Amount,
              (customerAmount || 0) <= +amount
                ? translateLang('insufficientFundsOnPA')
                : err.message
            );
          });
      }
    },
  });
  const handleDateChange = (newValue: Date | null) => {
    setFieldValue(PaymentFormFields.Date, newValue);
  };
  return (
    <Stack alignItems="center">
      <Stack width="100%" flexDirection="row" justifyContent="space-between" mb={2}>
        <IconButton onClick={handleClosePaymentForm} sx={{ p: 0 }}>
          <ArrowBack sx={{ color: theme.palette.primary.main }} />
        </IconButton>
        <IconButton onClick={handleClose} sx={{ p: 0 }}>
          <Icon icon="close" sx={{ color: theme.palette.primary.main }} />
        </IconButton>
      </Stack>

      <TranslatedField
        isTranslate
        variant="h4"
        color={theme.palette.common.darkBlue}
        marginBottom={2.5}
        fontSize={30}
        originText={
          payType === 'cash'
            ? 'cashPayment'
            : payType === 'bankTransfer'
            ? 'bankTransfer'
            : 'withdrawalOfOR'
        }
      />

      {payType === 'PA' ? (
        <Typography fontSize={20} fontWeight={300} color={theme.palette.common.black} mb={2.5}>
          {translateLang('PAAmount')}
          {': '}
          {typeof customerAmount === 'number' ? (
            <span style={{ fontWeight: 500, color: '#97A408' }}>{`${
              customerAmount > 0 ? '+' : ''
            }${customerAmount}€`}</span>
          ) : null}
        </Typography>
      ) : null}

      <Stack component="form" width="100%" mb={2} onSubmit={handleSubmit}>
        <Stack
          width="100%"
          justifyContent="space-between"
          flexDirection="row"
          alignItems="baseline"
          gap={3}
        >
          <TranslatedField
            originText={'amount'}
            fontSize={16}
            isTranslate
            noWrap
            overflow="initial"
          />
          <Input
            name={PaymentFormFields.Amount}
            value={`${values[PaymentFormFields.Amount]}€`}
            onChange={event => {
              const value = formatCurrencyAmount(event.target.value);

              setFieldValue(PaymentFormFields.Amount, value);
            }}
            error={touched[PaymentFormFields.Amount] && !!errors[PaymentFormFields.Amount]}
            helperText={touched[PaymentFormFields.Amount] && errors[PaymentFormFields.Amount]}
            inputProps={{
              style: {
                textAlign: 'center',
                paddingLeft: 0,
                color: theme.palette.common.black,
                boxShadow: ' 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset',
                height: 12,
              },
            }}
            bgcolor={theme.palette.common.white}
            sx={{
              ml: 'auto',
              maxWidth: '220px',
            }}
          />
        </Stack>
        {payType === 'cash' || payType === 'bankTransfer' ? (
          <Stack
            width="100%"
            height={75}
            justifyContent="space-between"
            flexDirection="row"
            alignItems="center"
            gap={3}
          >
            <TranslatedField
              originText={'date'}
              fontSize={16}
              isTranslate
              noWrap
              overflow="initial"
            />
            <CalendarMUI
              disableFuture={false}
              value={values[PaymentFormFields.Date]}
              isLabel={false}
              setValue={handleDateChange}
              inputStyle={{
                bgcolor: theme.palette.common.white,
                height: 40,
                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset',
                p: 0,
                '& .MuiInputBase-root': {
                  p: '5px 15px',
                },
              }}
            />
          </Stack>
        ) : null}
        {payType !== 'PA' ? (
          <Stack
            mt={2}
            width="100%"
            height={75}
            justifyContent="space-between"
            flexDirection="row"
            alignItems="baseline"
            gap={3}
          >
            <TranslatedField
              originText={'comment'}
              fontSize={16}
              isTranslate
              noWrap
              overflow="initial"
            />
            <Input
              name={PaymentFormFields.Description}
              value={values[PaymentFormFields.Description]}
              onChange={handleChange}
              error={
                touched[PaymentFormFields.Description] && !!errors[PaymentFormFields.Description]
              }
              helperText={
                touched[PaymentFormFields.Description] && errors[PaymentFormFields.Description]
              }
              inputProps={{
                style: {
                  color: theme.palette.common.black,
                  boxShadow: ' 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset',
                  height: 12,
                },
              }}
              bgcolor={theme.palette.common.white}
              sx={{
                ml: 'auto',
                maxWidth: '271px',
              }}
            />
          </Stack>
        ) : null}

        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={
            !dirty ||
            customerPaymentLoading ||
            customerOrderLoading ||
            (payType === 'PA' && (customerAmount || 0) <= 0)
          }
          startIcon={
            customerPaymentLoading || customerOrderLoading ? (
              <CircularProgress size={20} />
            ) : undefined
          }
          sx={{
            marginTop: 2,
            height: 50,
            p: 2,
            textTransform: 'none',
            backgroundColor: '#5269A3',
            borderRadius: '10px',
            '&:disabled': {
              color: theme.palette.common.white,
            },
          }}
        >
          <TranslatedField
            originText={payType === 'PA' ? 'withdraw' : 'pay'}
            fontSize={16}
            isTranslate
            noWrap
          />
        </Button>
      </Stack>
    </Stack>
  );
};
