import { FC, LazyExoticComponent, ReactNode } from 'react';
export enum PatchesName {
  Clients = 'clients',
  Catalog = 'catalog',
  TopProducts = 'topProducts',
  Translate = 'translate',
  Login = 'login',
  EmailConfirmation = 'email-confirmation',
  EnterCode = 'enterCode',
  ForgotPassword = 'forgotPassword',
  ResetPassword = 'forgot-password',
  Register = 'register',
  Orders = 'orders',
  NewOrder = 'newOrder',
  CreateOrder = 'createOrder',
  Feedback = 'feedback',
  Banner = 'banner',
  Warehouse = 'warehouse',
  Delivery = 'delivery',
  MobileDelivery = 'mobileDelivery',
  MobileDeliveryOrderInfo = 'mobileDeliveryOrderInfo',
  OrdersHistory = 'ordersHistory',
  MobileReturnOrder = 'return',
  AdminSettings = 'adminSettings',
  ManagerPanel = 'managerPanel',
  Accounting = 'accounting',
  ProviderReturn = 'provider-return',
}
export type AllFiltersType = {
  [key: string]: string;
};
export enum RolesEnum {
  SuperAdmin = 'SuperAdmin',
  Admin = 'Administrator',
  Manager = 'Manager',
  WarehouseManager = 'Warehouse Manager',
  Courier = 'Courier',
  Customer = 'Customer',
  Customer30 = 'Customer30',
  Customer40 = 'Customer40',
  Public = 'Public',
  PermanentWholesaleCustomer = 'Permanent Wholesale Customer',
  WholesaleCustomer = 'Wholesale Customer',
  Employee = 'Employee',
  Accounter = 'Accounter',
}
export type UrlParamsType =
  | '/:id'
  | '/:idCategory'
  | '/:idUser'
  | '/:idOrder'
  | '/:idUser/:idOrder';
export type UrlParamsObjectType = {
  id?: string;
  idCategory?: string;
  idUser?: string;
  idOrder?: string;
  barcode?: string;
};
export type RoutesPathsType = {
  title: PatchesName;
  subTitle?: PatchesName;
  icon?: ReactNode;
  urlParams?: UrlParamsType;
  role: Array<string>;
  Component?: LazyExoticComponent<FC>;
};
