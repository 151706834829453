import { useState } from 'react';
import { differenceInCalendarDays } from 'date-fns';

import {
  GetOrdersAccountingQuery,
  useGetOrdersAccountingLazyQuery,
  useGetOrdersAccountingQuery,
} from '../__generated__/getOrdersAccounting';
import { PaginationType } from './useGetOrders';
import { useLocalization } from '../../../localization';
import { handlerError } from '../../../helpers/functions';
import { PERIOD_FOR_PAYMENT } from '../../../helpers/constants';
import { Enum_Order_Status, OrderFiltersInput } from '../../../__generated__/types';
import { CustomerContactInfoFragment } from '../../fragments/__generated__/customerContactInfo';
import { formatOrderNumber } from '../../../utils/formatOrderNumber';

type InfoType = NonNullable<NonNullable<CustomerContactInfoFragment['data']>['attributes']>;

type PhoneNumbersType = NonNullable<InfoType>['phoneNumbers'];

export enum Enum_Order_Payment_Status {
  Paid = 'paid',
  NotPaid = 'notPaid',
  PartlyPaid = 'partlyPaid',
  Expired = 'expired',
}

export interface AllOrdersAccountingType {
  orderId: string;
  orderNumber?: string | null;
  orderData: string | null;
  fullPaidDate: string | null;
  billDate: string | null;
  billNumber: number | null;
  billId: string | null;
  customerPhone: PhoneNumbersType;
  customerId: string;
  customerInfo: string;
  total?: number | null;
  paymentTotal?: number | null;
  status?: Enum_Order_Status;
  statusPayment: Enum_Order_Payment_Status;
}
interface ReturnHook {
  allOrdersAccounting: Array<AllOrdersAccountingType>;
  loading: boolean;
  totalPage: number;
  total: number;
  refetch: () => void;
}

interface ReturnHookLazy {
  allOrdersAccounting: Array<AllOrdersAccountingType>;
  loading: boolean;
  getOrdersData: () => Promise<AllOrdersAccountingType[] | undefined>;
}

export interface OrderFilters extends OrderFiltersInput {
  searchNumbers?: string;
  searchStrings?: string;
  paymentTotalType?: string;
}

interface PropsHook {
  filters?: OrderFilters;
  sort?: string[];
  filterByCustomerId?: OrderFiltersInput;
  pagination?: PaginationType;
  page?: number;
  pageSize?: number;
}

interface PropsHookLazy {
  sort?: string;
  filters?: OrderFiltersInput;
  customerId?: string;
}

const FILTER_ALL_STATUSES = [
  {
    status: {
      eq: Enum_Order_Status.Ordered,
    },
  },
  {
    status: {
      eq: Enum_Order_Status.Awaiting,
    },
  },
  {
    status: {
      eq: Enum_Order_Status.InStock,
    },
  },
  {
    status: {
      eq: Enum_Order_Status.PickedUp,
    },
  },
  {
    status: {
      eq: Enum_Order_Status.Delivery,
    },
  },
  {
    status: {
      eq: Enum_Order_Status.Completed,
    },
  },
];

export const useLazyGetAllOrdersAccounting = ({
  sort = 'date:desc',
  filters,
}: PropsHookLazy): ReturnHookLazy => {
  const { updateSelectLanguage } = useLocalization();
  const [allOrders, setAllOrders] = useState<Array<AllOrdersAccountingType>>([]);
  const [runGetOrders, { loading }] = useGetOrdersAccountingLazyQuery({
    variables: {
      pagination: { limit: -1 },
      filters,
      sort: [sort],
      locale: updateSelectLanguage as string,
    },
  });
  const getOrdersData = async () => {
    try {
      const response = await runGetOrders();
      if (response.data) {
        const orders = getNormalizedOrders(response.data);
        setAllOrders(orders);
        return orders;
      }
      return [];
    } catch (error: unknown) {
      handlerError(error);
    }
  };

  return {
    allOrdersAccounting: allOrders,
    loading,
    getOrdersData,
  };
};

export const useGetAllOrdersAccounting = ({
  sort = ['date:desc'],
  filters,
  page,
  pageSize,
}: PropsHook): ReturnHook => {
  const { updateSelectLanguage } = useLocalization();

  const { data, loading, refetch } = useGetOrdersAccountingQuery({
    variables: {
      pagination: {
        page,
        pageSize,
      },
      sort,
      filters,
      locale: updateSelectLanguage as string,
    },
  });

  return {
    allOrdersAccounting: getNormalizedOrders(data),
    loading,
    total: data?.orders?.meta.pagination.total || 0,
    totalPage: data?.orders?.meta.pagination.pageCount || 0,
    refetch: refetch,
  };
};

function getStatusPayment(
  total: number,
  paymentTotal: number,
  orderData: string,
  isCompleted: boolean
): Enum_Order_Payment_Status {
  if (
    total > paymentTotal &&
    isCompleted &&
    differenceInCalendarDays(new Date(), new Date(orderData)) > PERIOD_FOR_PAYMENT
  ) {
    return Enum_Order_Payment_Status.Expired;
  }
  if (total === paymentTotal && total > 0) {
    return Enum_Order_Payment_Status.Paid;
  }
  if (paymentTotal && total > paymentTotal) {
    return Enum_Order_Payment_Status.PartlyPaid;
  }
  return Enum_Order_Payment_Status.NotPaid;
}

function getNormalizedOrders(
  data: GetOrdersAccountingQuery | undefined
): Array<AllOrdersAccountingType> {
  const allOrdersAccounting: Array<AllOrdersAccountingType> = [];

  if (data?.orders?.data.length) {
    data.orders.data.forEach(item => {
      const orderNumber = formatOrderNumber(
        item.attributes?.orderNumber,
        item.attributes?.orderNumberAdd
      );
      allOrdersAccounting.push({
        orderId: item.id || '',
        orderNumber,
        orderData: item.attributes?.date,
        customerId: item.attributes?.customer?.data?.id || '',
        fullPaidDate: item.attributes?.fullPaidDate,
        billDate: item.attributes?.customer_bill?.data?.attributes?.billDate,
        billNumber: item.attributes?.customer_bill?.data?.attributes?.billNumber ?? null,
        billId: item.attributes?.customer_bill?.data?.id ?? null,
        customerInfo:
          item.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes
            ?.firstName ||
          item.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes
            ?.lastName
            ? `${item.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes?.firstName} ${item.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes?.lastName}\xa0  #${item.attributes?.customer?.data?.id}`
            : item.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes
                ?.phoneNumbers?.length
            ? `${item.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes?.phoneNumbers?.[0]?.number}`
            : `Customer - #${item.attributes?.customer?.data?.id}`,
        customerPhone:
          item.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes
            ?.phoneNumbers,
        total: item.attributes?.total,
        paymentTotal: item.attributes?.paymentTotal || 0,
        status: item.attributes?.status,
        statusPayment: getStatusPayment(
          item.attributes?.total || 0,
          item.attributes?.paymentTotal || 0,
          item.attributes?.date,
          item.attributes?.status === Enum_Order_Status.Completed
        ),
      });
    });
  }

  return allOrdersAccounting;
}
