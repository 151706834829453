import React, { FC, useCallback, useMemo, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useLocalization } from '../../../localization';
import { OrderComment } from './OrderComment';
import { useGetOrderById } from '../../../graphql/queries/hook/useGetOrderById';
import {
  BoxCell,
  HeaderCell,
  TableCellsValue,
  TableProduct,
} from '../../../components/TableProduct/TableProduct';

import { RolesEnum, UrlParamsObjectType } from '../../../types/types';
import { useGetOrderItems } from '../../../graphql/queries/hook/useGetOrderItems';
import { Enum_Order_Action_Type } from '../../../__generated__/types';
import { useChangeStatusItemOrderMutation } from '../../../graphql/mutations/__generated__/changeStatusItemOrder';
import { formatDate, handlerError } from '../../../helpers/functions';
import { GetOrderItemsDocument } from '../../../graphql/queries/__generated__/getOrderItems';
import { useHandlerNotificationApp } from '../../../hooks/useHandlerNotificationApp';
import { ToolTypeComment } from '../../../components/ToolTypeComment';
import { OrderItemStatusesCell } from '../../../components/OrderItemStatuses/OrderItemStatusesCell';
import { OrderItemStatusesHeader } from '../../../components/OrderItemStatuses';
import { OrderItemQuantity } from '../../../components';
import { theme } from '../../../helpers';
import { ChangeCustomerPriceModal } from '../../CheckoutPage/components/ChangeCustomerPriceModal';
import { Edit } from '@mui/icons-material';
import { roundAmountToString } from '../../../utils/currencyUtils';
import { OrderItemType } from '../../../helpers/types';
import { useGetMe } from '../../../graphql/queries/hook/useGetMe';
import { ChangePriceForAllOrderItemsModal } from '../../CheckoutPage/components/ChangePriceForAllOrderItemsModal';

interface Props {
  orderId: string;
}

export const OrderDetailBox: FC<Props> = ({ orderId }) => {
  const { role } = useGetMe();

  const { translateLang, updateSelectLanguage } = useLocalization();
  const [runChangeStatusItemOrder] = useChangeStatusItemOrderMutation();
  const { addNotification } = useHandlerNotificationApp();
  const { idOrder = '' } = useParams<UrlParamsObjectType>();
  const { comment, total } = useGetOrderById(orderId);
  const [openChangeAllPriceModal, setOpenChangeAllPriceModal] = useState<boolean>(false);

  const { orderItems, loading, variablesQuery } = useGetOrderItems(idOrder);
  const [orderItemToChangePrice, setOrderItemToChangePrice] = useState<OrderItemType | null>(null);

  const orderItemAction = useCallback(
    async (partID: string, actionType: Enum_Order_Action_Type) => {
      if (!partID) {
        return;
      }
      try {
        const response = await runChangeStatusItemOrder({
          variables: {
            input: {
              actionData: {
                orderId: orderId,
                orderItemIds: [partID],
              },
              actionType,
              locale: updateSelectLanguage,
            },
          },
          refetchQueries: [{ query: GetOrderItemsDocument, variables: variablesQuery }],
        });
        if (response.data?.orderItemAction?.length) {
          response.data.orderItemAction.forEach(item => {
            if (item?.success) {
              addNotification({
                messageError: translateLang('statusFixed'),
                typeMessage: 'success',
              });
            } else {
              addNotification({
                messageError: translateLang('somethingWentWrong'),
                typeMessage: 'error',
              });
            }
          });
        }
      } catch (err: unknown) {
        handlerError(err);
      }
    },
    [
      addNotification,
      orderId,
      runChangeStatusItemOrder,
      translateLang,
      updateSelectLanguage,
      variablesQuery,
    ]
  );

  const headersTable = useMemo((): Array<HeaderCell> => {
    return [
      {
        title: '№',
        containerProps: { width: '15px', color: '#F1F3F8', align: 'center' },
      },
      {
        title: <OrderItemStatusesHeader translateLang={translateLang} />,
        containerProps: { color: '#F1F3F8', align: 'center' },
      },
      {
        title: translateLang('article'),
        containerProps: { width: '160px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('productName'),
        containerProps: { width: '160px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('manufacturer'),
        containerProps: { width: '140px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('provider'),
        containerProps: { width: '140px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('_delivery'),
        containerProps: { width: '140px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('quantity'),
        containerProps: { width: '120px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: (
          <Box display="flex" alignItems="center">
            {translateLang('price')}
            {role === RolesEnum.SuperAdmin ? (
              <IconButton
                onClick={() => {
                  setOpenChangeAllPriceModal(true);
                }}
              >
                <Edit sx={{ fontSize: '1rem' }} />
              </IconButton>
            ) : (
              ''
            )}
          </Box>
        ),
        containerProps: { width: '160px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('discount'),
        containerProps: { width: '160px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('productComment'),
        containerProps: { width: '260px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('amount'),
        containerProps: { width: '120px', color: '#f1f3f8', align: 'center' },
      },
    ];
  }, [role, translateLang]);

  const rowsTable = useMemo((): Array<TableCellsValue> => {
    if (orderItems && orderItems.length) {
      return orderItems.map((item, index) => {
        const values: Array<BoxCell> = [];
        const color =
          (item?.quantityAfterRemovingAsReturn ?? 0) === 0 ? theme.palette.common.grey : 'black';
        values.push({
          val: index + 1,
          containerProps: { width: '20px', height: '20px', borderLeft: 'none' },
        });
        values.push({
          val: <OrderItemStatusesCell orderItem={item} action={orderItemAction} />,
        });
        values.push({
          val: item?.article,
          containerProps: {
            color,
          },
        });
        values.push({
          val: item?.productName,
          containerProps: {
            color,
          },
        });
        values.push({
          val: item?.brandName,
          containerProps: {
            color,
          },
        });
        values.push({
          val: item?.provider,
          containerProps: {
            color,
          },
        });
        values.push({
          val: formatDate(item?.deliveryDate as string),
          containerProps: {
            color,
          },
        });
        values.push({
          val: (
            <OrderItemQuantity
              confirmedQuantity={item.confirmedQuantity ?? 0}
              quantity={item.quantity ?? 0}
              removedQuantity={item.returnedQuantity ?? 0}
            />
          ),
        });
        values.push({
          val: (
            <Box height="100%" display="flex" alignItems="center" position="relative">
              {roundAmountToString(item?.customerPrice ?? 0)}
              {role === RolesEnum.SuperAdmin ? (
                <IconButton onClick={() => setOrderItemToChangePrice(item)}>
                  <Edit />
                </IconButton>
              ) : null}
            </Box>
          ),
        });
        values.push({
          val: item?.discount || '0',
          containerProps: {
            color,
          },
        });
        values.push({ val: <ToolTypeComment comment={item?.comment} width="200px" /> });
        values.push({
          val: `${((item.customerPrice ?? 0) * (item?.quantityAfterRemovingAsReturn || 0)).toFixed(
            2
          )} €`,
          containerProps: {
            color,
          },
        });

        return { cellsValues: values };
      });
    }
    return [];
  }, [orderItemAction, orderItems, role]);

  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        mt: '32px',
        borderRadius: '10px',
        padding: '40px',
        mb: '32px',
      }}
    >
      <Typography
        sx={{ lineHeight: '37.65px', fontSize: '30px', fontWeight: '400', color: '#2A3453' }}
      >
        {translateLang('order')}
      </Typography>
      <TableProduct itemsRows={rowsTable} itemsHeader={headersTable} loading={loading} />
      <OrderComment comment={comment} orderAmount={total} />
      {!!orderItemToChangePrice && (
        <ChangeCustomerPriceModal
          dataProduct={orderItemToChangePrice}
          handleClose={() => {
            setOrderItemToChangePrice(null);
          }}
          open={!!orderItemToChangePrice}
        />
      )}
      {!!openChangeAllPriceModal && (
        <ChangePriceForAllOrderItemsModal
          orderItems={orderItems}
          orderId={orderId}
          handleClose={() => {
            setOpenChangeAllPriceModal(false);
          }}
          open={!!openChangeAllPriceModal}
        />
      )}
    </Box>
  );
};
