import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

type ExportToExcelParams = {
  data: string[][];
  headers: string[];
  fileName: string;
};

export const useExportToExcel = () => {
  const downloadExcel = async ({ data, headers, fileName }: ExportToExcelParams) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell(cell => {
      cell.font = { bold: true };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D3D3D3' },
      };
      cell.border = {
        top: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } },
      };
    });

    data.forEach(row => {
      worksheet.addRow(row);
    });

    worksheet.columns.forEach((column, colIndex) => {
      if (colIndex === 0 && column && column.eachCell) {
        column.eachCell({ includeEmpty: true }, cell => {
          cell.font = { bold: true };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'D3D3D3' },
          };
          cell.alignment = { horizontal: 'center' };
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          };
        });
      }
    });
    worksheet.eachRow(row => {
      row.height = 20;
    });
    if (worksheet.columns) {
      worksheet.columns.forEach(column => {
        let maxLength = 10;
        column.eachCell?.({ includeEmpty: true }, cell => {
          if (cell.value) {
            maxLength = Math.max(maxLength, cell.value.toString().length);
          }
        });
        column.width = maxLength + 2;
      });
    }

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, fileName || 'export.xlsx');
  };

  return downloadExcel;
};
