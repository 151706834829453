import React, { useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { addDays, format } from 'date-fns';
import { useLocalization } from '../../../../localization';
import { useGetProvidersReturns } from './hooks/useGetProvidersReturns';
import { ProvidersReturnsTabTable } from './ProvidersReturnsTabTable';
import { ToolBarProvidersReturnsTab } from './ToolBarProvidersReturnsTab';
import { SearchInput } from '../../../../components/SearchInput';
import { PAGE_COUNT } from '../../../../helpers/constants';
import { useWarehouseReturnsFromProviderTabSearchParams } from './hooks/useWarehouseReturnsFromProvidersTabSearchParams';
import { CreateUpdateReturnModal } from './CreateUpdateReturnModal';

export const ProvidersReturnsTab = () => {
  const [openReturnModal, setOpenReturnModal] = useState(false);
  const { translateLang } = useLocalization();
  const [isEdit, setEdit] = useState<boolean>(false);
  const closeReturnModal = () => {
    setOpenReturnModal(false);
  };

  const {
    page,
    status,
    filters,
    searchText,
    changeParam,
    resetFilters,
    selectedDates,
    changeSearchText,
  } = useWarehouseReturnsFromProviderTabSearchParams({
    defaultDateFrom: format(addDays(new Date(), -30), 'yyyy-MM-dd'),
    defaultDateTo: format(new Date(), 'yyyy-MM-dd'),
  });

  const {
    providersReturns,
    totalPage,
    total,
    isLoading: providersReturnsLoading,
  } = useGetProvidersReturns({
    filters,
    pagination: {
      page: parseInt(page),
      pageSize: PAGE_COUNT,
    },
  });

  return (
    <Stack width="100%" paddingBottom={10}>
      <Box style={{ display: 'flex', justifyContent: 'end', marginBottom: '50px' }}>
        <Button
          style={{ width: '220px' }}
          variant="contained"
          onClick={() => setOpenReturnModal(true)}
        >
          {translateLang('createReturn')}
        </Button>
      </Box>
      <SearchInput
        onChangeText={changeSearchText}
        placeholder={translateLang('search_provider_returns')}
        value={searchText}
      />
      <ToolBarProvidersReturnsTab
        result={total}
        status={status}
        selectedDates={selectedDates}
        resetFilters={resetFilters}
        changeParam={changeParam}
      />

      <ProvidersReturnsTabTable
        returnedItems={providersReturns}
        loading={providersReturnsLoading}
        changePage={page => changeParam({ page: `${page + 1}` })}
        currentPage={parseInt(page)}
        totalPage={totalPage}
      />
      <CreateUpdateReturnModal isEdit={isEdit} open={openReturnModal} close={closeReturnModal} />
    </Stack>
  );
};
