import React, { ReactNode, useCallback } from 'react';
import { HeaderCell } from '../../../../../components/TableProduct/TableProduct';
import { useLocalization } from '../../../../../localization';
import { TableProvidersReturnedItemRow } from '../TablesComponents/TableProvidersReturnedItemRow';
export type ReturnType = {
  id?: string | null;
  article?: string | null;
  quantity?: number | null;
  price?: number | null;
  productName: string | null;
};

interface Props {
  providerReturnItems: Array<{
    id?: string | null;
    article?: string | null;
    quantity?: number | null;
    price?: number | null;
    productName?: string | null;
    manufacturer?: string | null;
    amount?: number | null;
  }>;
  refetchProviderReturnItems: () => void;
}
interface ReturnHook {
  getHeadersTable(): Array<HeaderCell>;
  getRowsTableActive(): ReactNode[];
}

export const useGetProviderReturnedItemsTable = ({
  providerReturnItems,
  refetchProviderReturnItems,
}: Props): ReturnHook => {
  const { translateLang } = useLocalization();
  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      {
        title: translateLang('№'),
        containerProps: { width: '20px', color: '#F1F3F8', align: 'center' },
      },
      {
        title: translateLang('article'),
        containerProps: { width: '160px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('productName'),
        containerProps: { width: '160px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('manufacturer'),
        containerProps: { width: '140px', color: '#f1f3f8', align: 'center' },
      },

      {
        title: translateLang('quantityReturned'),
        containerProps: { width: '120px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('providerPrice'),
        containerProps: { width: '120px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('amount'),
        containerProps: { width: '120px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('del'),
        containerProps: { width: '120px', color: '#f1f3f8', align: 'center' },
      },
    ];
  };
  const getRowsTableActive = useCallback((): ReactNode[] => {
    if (providerReturnItems && providerReturnItems.length) {
      return providerReturnItems?.map((item, index) => {
        return (
          <TableProvidersReturnedItemRow
            key={index}
            itemRow={item}
            refetchProviderReturnItems={refetchProviderReturnItems}
          />
        );
      });
    }
    return [];
  }, [providerReturnItems]);

  return { getHeadersTable, getRowsTableActive };
};
