import React, { FC } from 'react';
import { Stack, Box, Button } from '@mui/material';
import { useLocalization } from '../../../localization';

type Tabs = {
  value: string;
  id: number;
  notification: number;
};

interface Props {
  activeTable: string | null;
  handleChange: (val: string) => void;
}

const tabs: Tabs[] = [
  { id: 1, value: 'expected', notification: 0 },
  { id: 2, value: 'order', notification: 0 },
  { id: 3, value: 'returnFromDelivery', notification: 0 },
  { id: 4, value: 'returnsFromCustomer', notification: 0 },
  { id: 5, value: 'returnsFromProvider', notification: 0 },
  { id: 6, value: 'warehouse', notification: 0 },
];

export const TableChangeButton: FC<Props> = ({ activeTable, handleChange }) => {
  const { translateLang } = useLocalization();

  return (
    <Stack
      sx={{
        height: '48px',
        backgroundColor: 'white',
        width: { sm: '100%', md: '90%', lg: '60%' },
        borderRadius: '10px',
        paddingRight: '30px',
      }}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      divider={
        <Box
          sx={{
            width: '0.5px',
            backgroundColor: '#2A3453',
            height: '27px',
            marginLeft: '20px',
          }}
        />
      }
    >
      {tabs.map(tab => (
        <Button
          key={tab.id}
          fullWidth={true}
          variant={activeTable === tab.value ? 'contained' : 'text'}
          color={activeTable === tab.value ? 'primary' : 'secondary'}
          sx={{
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '18px',
            marginX: '10px',
            paddingX: '10px',
            textTransform: 'none',
          }}
          onClick={() => handleChange(tab.value)}
        >
          {translateLang(`${tab.value}`)}
        </Button>
      ))}
    </Stack>
  );
};
