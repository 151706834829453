import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "StaffSetting",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type GetSupplierReturnQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
  locale?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetSupplierReturnQuery = { __typename?: 'Query', supplierReturn?: { __typename?: 'SupplierReturnEntityResponse', data?: { __typename?: 'SupplierReturnEntity', id?: string | null, attributes?: { __typename?: 'SupplierReturn', status?: Types.Enum_Supplierreturn_Status | null, dateReturned?: any | null, total?: number | null, supplier?: { __typename?: 'SupplierEntityResponse', data?: { __typename?: 'SupplierEntity', attributes?: { __typename?: 'Supplier', name?: string | null } | null } | null } | null, supplier_return_items?: { __typename?: 'SupplierReturnItemRelationResponseCollection', data: Array<{ __typename?: 'SupplierReturnItemEntity', id?: string | null, attributes?: { __typename?: 'SupplierReturnItem', quantityReturned?: number | null, supplierPrice?: number | null, createdAt?: any | null, stock?: { __typename?: 'StockEntityResponse', data?: { __typename?: 'StockEntity', id?: string | null, attributes?: { __typename?: 'Stock', supplierPrice?: number | null, incomingQuantity?: number | null, incoming_invoice?: { __typename?: 'IncomingInvoiceEntityResponse', data?: { __typename?: 'IncomingInvoiceEntity', id?: string | null, attributes?: { __typename?: 'IncomingInvoice', supplierInvoiceNumber?: string | null, supplierInvoiceDate?: any | null, supplierInvoiceTotal?: number | null } | null } | null } | null, car_spare?: { __typename?: 'CarSpareEntityResponse', data?: { __typename?: 'CarSpareEntity', id?: string | null, attributes?: { __typename?: 'CarSpare', tecdoc_articleNumber?: string | null, car_spare_locales?: { __typename?: 'CarSpareLocaleRelationResponseCollection', data: Array<{ __typename?: 'CarSpareLocaleEntity', id?: string | null, attributes?: { __typename?: 'CarSpareLocale', title?: string | null, articleCriteria?: any | null, brandName?: string | null } | null }> } | null } | null } | null } | null } | null } | null } | null } | null }> } | null } | null } | null } | null };


export const GetSupplierReturnDocument = gql`
    query getSupplierReturn($id: ID, $locale: String) {
  supplierReturn(id: $id) {
    data {
      id
      attributes {
        status
        dateReturned
        total
        supplier {
          data {
            attributes {
              name
            }
          }
        }
        supplier_return_items {
          data {
            id
            attributes {
              quantityReturned
              supplierPrice
              createdAt
              stock {
                data {
                  id
                  attributes {
                    supplierPrice
                    incomingQuantity
                    incoming_invoice {
                      data {
                        id
                        attributes {
                          supplierInvoiceNumber
                          supplierInvoiceDate
                          supplierInvoiceTotal
                        }
                      }
                    }
                    car_spare {
                      data {
                        id
                        attributes {
                          tecdoc_articleNumber
                          car_spare_locales(filters: {locale: {eq: $locale}}) {
                            data {
                              id
                              attributes {
                                title
                                articleCriteria
                                brandName
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetSupplierReturnQuery__
 *
 * To run a query within a React component, call `useGetSupplierReturnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierReturnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierReturnQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetSupplierReturnQuery(baseOptions?: Apollo.QueryHookOptions<GetSupplierReturnQuery, GetSupplierReturnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupplierReturnQuery, GetSupplierReturnQueryVariables>(GetSupplierReturnDocument, options);
      }
export function useGetSupplierReturnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupplierReturnQuery, GetSupplierReturnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupplierReturnQuery, GetSupplierReturnQueryVariables>(GetSupplierReturnDocument, options);
        }
export type GetSupplierReturnQueryHookResult = ReturnType<typeof useGetSupplierReturnQuery>;
export type GetSupplierReturnLazyQueryHookResult = ReturnType<typeof useGetSupplierReturnLazyQuery>;
export type GetSupplierReturnQueryResult = Apollo.QueryResult<GetSupplierReturnQuery, GetSupplierReturnQueryVariables>;