import React, { FC, DragEvent } from 'react';
import { Box } from '@mui/system';
import { Divider } from '@mui/material';
import { CheckBox, DragIndicator } from '@mui/icons-material';

import { Board } from './DeviderModal';
import { OrderItemType } from '../../../../../../helpers/types';

interface Props {
  board: Board;
  article: string;
  draggable: boolean;
  item: OrderItemType;
  productName: string;
  customerName: string;
  dragEndHandler: (event: DragEvent<HTMLDivElement>) => void;
  dragOverHandler: (event: DragEvent<HTMLDivElement>) => void;
  dragLeaveHandler: (event: DragEvent<HTMLDivElement>) => void;
  dragDropHandler: (event: DragEvent<HTMLDivElement>, item: OrderItemType, board: Board) => void;
  dragStartHandler: (event: DragEvent<HTMLDivElement>, item: OrderItemType, board: Board) => void;
}

export const ItemOrderCard: FC<Props> = ({
  item,
  board,
  article,
  draggable,
  productName,
  customerName,
  dragEndHandler,
  dragOverHandler,
  dragDropHandler,
  dragLeaveHandler,
  dragStartHandler,
}) => {
  const isGoingProductToWarehouse = item?.quantity === item?.outgoingQuantity;
  return (
    <div
      className="item"
      draggable={draggable}
      onDragEnd={e => dragEndHandler(e)}
      onDragOver={e => dragOverHandler(e)}
      onDragLeave={e => dragLeaveHandler(e)}
      onDrop={e => dragDropHandler(e, item, board)}
      onDragStart={e => dragStartHandler(e, item, board)}
      style={{
        height: '52px',
        cursor: 'grab',
        display: 'flex',
        marginBottom: '5px',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <DragIndicator sx={{ color: '#5F5F5F' }} />
      <Box
        height="52px"
        width="500px"
        display="flex"
        bgcolor="white"
        borderRadius="5px"
        alignItems="center"
        flexDirection="row"
      >
        <CheckBox
          sx={{ color: isGoingProductToWarehouse ? '#97A408' : '#e2e2e2', paddingX: '5px' }}
          fontSize="small"
        />
        <Divider orientation="vertical" variant="middle" flexItem />
        <Box
          width="30%"
          paddingX={1}
          height="32px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {article}
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Box
          width="35%"
          height="32px"
          display="flex"
          paddingX="10px"
          alignItems="center"
          justifyContent="center"
        >
          {productName}
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Box
          width="25%"
          paddingX={1}
          height="32px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {customerName}
        </Box>
      </Box>
    </div>
  );
};
