import {
  Enum_Supplierreturn_Status,
  Pagination,
  PaginationArg,
  SupplierReturnFiltersInput,
} from '../../../../../__generated__/types';
import { useGetSupplierReturnsQuery } from '../../../../../graphql/queries/__generated__/getSupplierReturns';

export interface ReturnType {
  id?: string | null;
  providerId?: string | null;
  name: string | null;
  correctiveInvoiceDate: Date;
  correctiveInvoiceNumber?: string | null;
  dateOfReceiving: Date;
  dateReturned: Date;
  total: number | null;
  status?: Enum_Supplierreturn_Status | null;
}

export type PaginationTransactionsType = Omit<Pagination, '__typename'>;

interface ReturnHook {
  providersReturns: Array<ReturnType>;
  pagination?: PaginationTransactionsType;
  isLoading?: boolean;
  refetchProvidersReturns: () => void;
  totalPage: number;
  total: number;
}

export type FiltersType = {
  searchStrings?: string;
  searchNumbers?: string;
};
interface PropsHook {
  pagination?: PaginationArg;
  filters: SupplierReturnFiltersInput;
  skip?: boolean;
  page?: number;
  pageSize?: number;
}

export const useGetProvidersReturns = ({
  pagination,
  filters,
  skip = false,
}: PropsHook): ReturnHook => {
  const {
    data: supplierReturnsData,
    loading: providersReturnsLoading,
    refetch: refetchProvidersReturns,
  } = useGetSupplierReturnsQuery({
    skip,
    variables: {
      filters,
      pagination,
      sort: ['createdAt:desc'],
    },
  });

  if (supplierReturnsData?.supplierReturns?.data) {
    const returnsData = supplierReturnsData?.supplierReturns.data;
    const providersReturns = returnsData.map(item => {
      return {
        id: item?.id,
        providerId: item?.attributes?.supplier?.data?.id,
        name: item?.attributes?.supplier?.data?.attributes?.name ?? '',
        dateOfReceiving:
          item?.attributes?.supplier_return_items?.data[0]?.attributes?.stock?.data?.attributes
            ?.incoming_invoice?.data?.attributes?.supplierInvoiceDate,
        correctiveInvoiceDate: item?.attributes?.correctiveInvoiceDate,
        correctiveInvoiceNumber: item.attributes?.correctiveInvoiceNumber,
        dateReturned: item.attributes?.dateReturned,
        status: item.attributes?.status,
        total: item?.attributes?.total ?? null,
      };
    });
    const pagination: PaginationTransactionsType = {
      page: supplierReturnsData?.supplierReturns.meta.pagination.page,
      pageCount: supplierReturnsData?.supplierReturns.meta.pagination.pageCount,
      pageSize: supplierReturnsData?.supplierReturns.meta.pagination.pageSize,
      total: supplierReturnsData?.supplierReturns.meta.pagination.total,
    };
    return {
      providersReturns,
      pagination,
      isLoading: providersReturnsLoading,
      refetchProvidersReturns,
      totalPage: pagination.pageCount,
      total: pagination.total,
    };
  }

  return {
    providersReturns: [],
    isLoading: providersReturnsLoading,
    refetchProvidersReturns,
    totalPage: supplierReturnsData?.supplierReturns?.meta?.pagination.pageCount ?? 0,
    total: supplierReturnsData?.supplierReturns?.meta?.pagination.total ?? 0,
  };
};
