import React, { FC, useState } from 'react';
import { Button } from '@mui/material';
import { Icon } from '../../../../../legos';
import { useLocalization } from '../../../../../localization';
import { OrderFilters } from '../../../../../graphql/queries/hook/useGetAllOrdersAccounting';
import { useOrdersToExportLazyQuery } from './query/__generated__/ordersToExport';
import { formatOrderNumber } from '../../../../../utils/formatOrderNumber';
import { formatDate } from '../../../../../helpers/functions';
import { getUserFullName } from '../../../../../utils/getUserFullName';
import { roundAmountToString } from '../../../../../utils/currencyUtils';
import { useExportToExcel } from '../../../../../hooks/useExportToExcel';

type ExportOrdersToExcelProps = {
  filters: OrderFilters;
  sort: string[];
};

export const ExportOrdersToExcel: FC<ExportOrdersToExcelProps> = ({ filters, sort }) => {
  const { translateLang, selectLanguages } = useLocalization();
  const [loading, setLoading] = useState(false);

  const exportToExcel = useExportToExcel();
  const [runOrdersToExport] = useOrdersToExportLazyQuery();

  const exportHandler = async () => {
    try {
      setLoading(true);
      const { data } = await runOrdersToExport({
        variables: {
          locale: selectLanguages ?? 'en',
          filters,
          sort,
        },
      });

      const ordersData: string[][] = [];

      data?.orders?.data.forEach(order => {
        const orderAttributes = order.attributes;
        const orderInfo: string[] = [
          `#${formatOrderNumber(orderAttributes?.orderNumber, orderAttributes?.orderNumberAdd)}`,
          formatDate(orderAttributes?.date),
          getUserFullName(orderAttributes?.customer?.data),
          roundAmountToString(orderAttributes?.total),
          roundAmountToString((orderAttributes?.total ?? 0) - (orderAttributes?.paymentTotal ?? 0)),
          orderAttributes?.status ?? '',
          `${
            orderAttributes?.customer_bill?.data?.attributes?.billNumber
              ? `#${orderAttributes?.customer_bill?.data?.attributes?.billNumber}`
              : '-'
          }`,
          formatDate(orderAttributes?.customer_bill?.data?.attributes?.billDate) || '-',
        ];

        orderAttributes?.order_items?.data?.forEach(item => {
          const quantity =
            (item.attributes?.quantity?.orderedQuantity ?? 0) -
            (item.attributes?.quantity?.returnedQuantity ?? 0);
          if (quantity) {
            ordersData.push([
              ...orderInfo,
              item.attributes?.car_spare?.data?.attributes?.tecdoc_articleNumber ?? '',
              item.attributes?.car_spare?.data?.attributes?.car_spare_locales?.data?.[0]?.attributes
                ?.title ?? '',
              item.attributes?.car_spare?.data?.attributes?.car_spare_locales?.data?.[0]?.attributes
                ?.brandName ?? '',
              roundAmountToString(item.attributes?.customer_price),
              roundAmountToString(quantity),
            ]);
          }
        });
      });

      exportToExcel({
        data: ordersData,
        fileName: 'orders.xlsx',
        headers: [
          translateLang('numberOrder'),
          translateLang('orderDate'),
          translateLang('customer'),
          translateLang('orderAmount'),
          translateLang('debtAmount'),
          translateLang('status'),
          translateLang('invoiceBill'),
          translateLang('invoiceBillDate'),
          translateLang('article'),
          translateLang('productName'),
          translateLang('manufacturer'),
          translateLang('price'),
          translateLang('quantity'),
        ],
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      disabled={loading}
      sx={{ height: 40, color: '#97A408', borderColor: '#97A408', borderRadius: '10px' }}
      onClick={exportHandler}
      variant="outlined"
      startIcon={<Icon icon="fileDownload" color="inherit" />}
    >
      {translateLang('exportToExcel')}
    </Button>
  );
};
