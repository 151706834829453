import React, { FC, useMemo, useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useLocalization } from '../../../../../../localization';
import { CustomModal } from '../../../../../../components';
import { TranslatedField } from '../../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { InformItem } from '../../../../../CheckoutPage/components/ChangeCustomerPriceModal';
import { roundAmountToString } from '../../../../../../utils/currencyUtils';
import { useUpdateStockOrderItemMutation } from '../../../../../../graphql/mutations/__generated__/updateStockOrderitem';
import { handlerError } from '../../../../../../helpers/functions';
import { useHandlerNotificationApp } from '../../../../../../hooks/useHandlerNotificationApp';
import { theme } from '../../../../../../helpers';

interface Props {
  isOpen: boolean;
  stockId: string;
  article: string;
  oldPrice: number;
  supplier: string;
  brandName: string;
  closeModal(): void;
  productName: string;
}

export const EditSupplierPriceModal: FC<Props> = ({
  isOpen,
  stockId,
  article,
  oldPrice,
  supplier,
  brandName,
  closeModal,
  productName,
}) => {
  const { translateLang } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();

  const [newPrice, setNewPrice] = useState(roundAmountToString(oldPrice));

  const [runUpdateStock, { loading }] = useUpdateStockOrderItemMutation();

  const allowChange = useMemo(() => {
    const parseNewPrice = parseFloat(newPrice);

    return !isNaN(parseNewPrice) && parseNewPrice >= 0.01 && parseNewPrice !== oldPrice;
  }, [newPrice, oldPrice]);

  const handleSave = async () => {
    try {
      if (allowChange) {
        const parseNewPrice = parseFloat(newPrice);

        await runUpdateStock({
          variables: {
            id: stockId,
            data: {
              supplierPrice: parseNewPrice,
            },
          },
        });
        addNotification({
          messageError: translateLang('priceSuccessfullyChanged'),
          typeMessage: 'success',
        });
      }
      closeModal();
    } catch (err: unknown) {
      addNotification({
        messageError: (err as Error)?.message || 'Something went wrong',
        typeMessage: 'error',
      });
    }
  };

  return (
    <CustomModal open={isOpen} handleClose={closeModal} title={translateLang('EditSupplierPrice')}>
      <Box
        pt={3}
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <InformItem
          label={translateLang('article')}
          value={
            <Typography variant="h4" color={theme.palette.common.darkBlue} fontSize={18}>
              {article}
            </Typography>
          }
        />
        <InformItem
          label={translateLang('provider')}
          value={
            <Typography variant="h4" color={theme.palette.common.darkBlue} fontSize={18}>
              {supplier}
            </Typography>
          }
        />
        <InformItem
          label={translateLang('productName')}
          value={
            <Typography
              variant="h4"
              color={theme.palette.common.darkBlue}
              fontWeight={500}
              fontSize={18}
            >
              {productName}
            </Typography>
          }
        />

        <InformItem
          label={translateLang('manufacturer')}
          value={
            <Typography variant="h4" color={theme.palette.common.darkBlue} fontSize={18}>
              {brandName}
            </Typography>
          }
        />

        <InformItem
          label={translateLang('supplierPrice')}
          value={
            <TextField
              autoComplete="off"
              value={newPrice}
              InputProps={{ inputProps: { min: 0 } }}
              onChange={e => setNewPrice(e.target.value)}
              type="number"
            />
          }
        />

        <TranslatedField
          isTranslate
          fontSize={16}
          color={theme.palette.warning.main}
          originText="pleaseNoteChangingSupplierPrice"
        />

        <Button
          fullWidth
          color="secondary"
          variant="contained"
          disabled={loading || !allowChange}
          onClick={handleSave}
          style={{
            marginTop: '20px',
          }}
        >
          <TranslatedField
            noWrap
            isTranslate
            fontSize={16}
            color="white"
            originText={translateLang('save')}
          />
        </Button>
      </Box>
    </CustomModal>
  );
};
