import React, { FC, useState } from 'react';
import _ from 'lodash';
import { TableProductActive } from '../../../../../components/TableProductActive/TableProductActive';
import { Box, IconButton } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { ChangeQuantityReturnItemModal } from './ChangeQuantityReturnItemModal';
import { DeleteModal } from '../../../../ClientInfo/components/Modals/DeleteModal';
import { useLocalization } from '../../../../../localization';
import { useDeleteSupplierReturnItemMutation } from '../../../../../graphql/queries/__generated__/deleteSupplierReturnItem';
import { useHandlerNotificationApp } from '../../../../../hooks/useHandlerNotificationApp';

export type ReturnType = {
  id?: string | null;
  article?: string | null;
  quantity?: number | null;
  price?: number | null;
  productName?: string | null;
  manufacturer?: string | null;
  amount?: number | null;
};

interface Props {
  itemRow?: ReturnType | null;
  refetchProviderReturnItems: () => void;
}
export const TableProvidersReturnedItemRow: FC<Props> = ({
  itemRow,
  refetchProviderReturnItems,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isOpenQuantity, setOpenQuantity] = useState<boolean>(false);
  const { translateLang } = useLocalization();
  const [runDeleteSupplierReturnItem] = useDeleteSupplierReturnItemMutation({});
  const { addNotification } = useHandlerNotificationApp();

  const handlerDelete = async () => {
    try {
      await runDeleteSupplierReturnItem({
        variables: {
          id: itemRow?.id ?? '',
        },
      });
      addNotification({
        messageError: translateLang('returnItemSuccessfullyDeleted'),
        typeMessage: 'success',
      });
      handleClose();
      refetchProviderReturnItems();
    } catch (e: any) {
      console.error(e);
    }
  };

  const handleCloseQuantity = () => {
    setOpenQuantity(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <TableProductActive.RowTable>
        <TableProductActive.CellTable ceilStyle={{ width: '20px', borderLeft: 'none' }}>
          {itemRow?.id}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable>{itemRow?.article ?? ''}</TableProductActive.CellTable>
        <TableProductActive.CellTable>{itemRow?.productName ?? ''} </TableProductActive.CellTable>
        <TableProductActive.CellTable>{itemRow?.manufacturer ?? ''} </TableProductActive.CellTable>
        <TableProductActive.CellTable>
          <Box display="flex" alignItems="center">
            {itemRow?.quantity ?? ''}
            <IconButton onClick={() => setOpenQuantity(true)}>
              <Edit sx={{ fontSize: '18px' }} />
            </IconButton>
          </Box>
        </TableProductActive.CellTable>
        <TableProductActive.CellTable>{itemRow?.price ?? ''} €</TableProductActive.CellTable>
        <TableProductActive.CellTable>{itemRow?.amount ?? ''}</TableProductActive.CellTable>
        <TableProductActive.CellTable>
          {' '}
          <IconButton onClick={() => setOpen(true)}>
            <Delete sx={{ fontSize: '18px' }} />
          </IconButton>
        </TableProductActive.CellTable>
      </TableProductActive.RowTable>
      {isOpen && (
        <DeleteModal
          title={translateLang('deleteReturnedItem')}
          text={translateLang('wantDeleteReturnedItem')}
          closeModal={handleClose}
          openModal={isOpen}
          handlerDelete={handlerDelete}
        />
      )}
      {isOpenQuantity && (
        <ChangeQuantityReturnItemModal
          dataProduct={itemRow}
          handleClose={handleCloseQuantity}
          open={isOpenQuantity}
          refetchProviderReturnItems={refetchProviderReturnItems}
        />
      )}
    </>
  );
};
