import React, { FC, useState } from 'react';
import _ from 'lodash';
import { TableProductActive } from '../../../../../components/TableProductActive/TableProductActive';
import { formatDate } from '../../../../../helpers/functions';
import { ReturnType } from '../hooks/useGetProvidersReturns';
import { IconButton, Stack } from '@mui/material';
import { Edit, RemoveRedEyeOutlined } from '@mui/icons-material';
import { RolesEnum } from '../../../../../types/types';
import { useGetMe } from '../../../../../graphql/queries/hook/useGetMe';
import { CreateUpdateReturnModal } from '../CreateUpdateReturnModal';
import { useNavigate } from 'react-router-dom';
import { PatchesName } from '../../../../../types/types';
import { Enum_Supplierreturn_Status } from '../../../../../__generated__/types';
interface Props {
  itemRow?: ReturnType;
  returnedItems: ReturnType[];
}
export const TableProvidersReturnedItemsRow: FC<Props> = ({ itemRow }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { role } = useGetMe();

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <TableProductActive.RowTable>
        <TableProductActive.CellTable ceilStyle={{ width: '20px', borderLeft: 'none' }}>
          {itemRow?.id}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable>{itemRow?.name ?? ''}</TableProductActive.CellTable>
        <TableProductActive.CellTable>
          {formatDate(itemRow?.dateReturned ?? '')}{' '}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable>
          {formatDate(itemRow?.correctiveInvoiceDate ?? '')}{' '}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable>
          {itemRow?.correctiveInvoiceNumber ?? ''}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable>{itemRow?.total ?? ''} €</TableProductActive.CellTable>
        <TableProductActive.CellTable>
          {_.capitalize(itemRow?.status ?? '')}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable
          ceilStyle={{ borderLeft: 'none', alignItems: 'end', gap: '10px' }}
        >
          {role === RolesEnum.SuperAdmin || role === RolesEnum.WarehouseManager ? (
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <IconButton
                disabled={itemRow?.status === Enum_Supplierreturn_Status.Completed}
                onClick={() => {
                  localStorage.setItem('providerReturn', JSON.stringify(itemRow));
                  navigate(`/${PatchesName.Warehouse}/${itemRow?.id}`);
                }}
                sx={{ bgcolor: '#F1F3F8 !important' }}
              >
                <RemoveRedEyeOutlined sx={{ bgcolor: '#F1F3F8' }} />
              </IconButton>
            </Stack>
          ) : null}
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <IconButton onClick={() => setOpen(true)} sx={{ bgcolor: '#F1F3F8' }}>
              <Edit />
            </IconButton>
          </Stack>
        </TableProductActive.CellTable>
      </TableProductActive.RowTable>
      <CreateUpdateReturnModal open={isOpen} close={handleClose} isEdit={true} item={itemRow} />
    </>
  );
};
