import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetSupplierReturnItemsQuery } from '../../../../graphql/queries/__generated__/getSupplierReturnItems';

import { TableProductActive } from '../../../../components/TableProductActive/TableProductActive';
import { useGetProviderReturnedItemsTable } from './hooks/useGetProviderReturnedItemsTable';
import { WarehouseTab } from '../WarehouseTab/WarehouseTab';
import { Box } from '@mui/material';
import { useLocalization } from '../../../../localization';
import { formatDate } from '../../../../helpers/functions';
export type ProviderReturnsItemsType = {
  id?: string | null;
  providerId?: string | null;
  stockId?: string | null;
  article?: string | null;
  manufacturer?: string | null;
  productName?: string | null;
  quantity?: number | null;
  price?: number | null;
  amount?: number | null;
}[];

export const ProviderReturnedItemsPage = () => {
  const { returnId = '' } = useParams();
  const { translateLang } = useLocalization();
  const [, setSearchParams] = useSearchParams({});
  const providerReturn = JSON.parse(localStorage.getItem('providerReturn') ?? '');
  const {
    data: providerReturnData,
    loading,
    refetch: refetchProviderReturnItems,
  } = useGetSupplierReturnItemsQuery({
    variables: {
      filters: {
        supplier_return: {
          id: { eq: returnId },
        },
      },
      pagination: { limit: -1 },
    },
  });

  const providerReturnsItems =
    providerReturnData?.supplierReturnItems?.data.map(item => {
      return {
        id: item.id,
        providerId: item?.attributes?.supplier_return?.data?.attributes?.supplier?.data?.id,
        providerName:
          item?.attributes?.supplier_return?.data?.attributes?.supplier?.data?.attributes?.name,
        stockId: item?.attributes?.stock?.data?.id,
        article:
          item?.attributes?.stock?.data?.attributes?.car_spare?.data?.attributes
            ?.tecdoc_articleNumber,
        manufacturer:
          item?.attributes?.stock?.data?.attributes?.car_spare?.data?.attributes?.car_spare_locales
            ?.data[0].attributes?.brandName,
        productName:
          item?.attributes?.stock?.data?.attributes?.car_spare?.data?.attributes?.car_spare_locales
            ?.data[0].attributes?.title,
        quantity: item.attributes?.quantityReturned,
        price: item?.attributes?.supplierPrice,
        amount: (item?.attributes?.supplierPrice ?? 0) * (item.attributes?.quantityReturned ?? 0),
      };
    }) || [];

  const { getHeadersTable, getRowsTableActive } = useGetProviderReturnedItemsTable({
    providerReturnItems: providerReturnsItems,
    refetchProviderReturnItems: refetchProviderReturnItems,
  });
  return (
    <Box display="flex" flexDirection="column" gap={7}>
      <Box>
        <Box>{translateLang('provider') + ': ' + providerReturn?.name}</Box>
        <Box>
          {translateLang('returnDate') + ': ' + formatDate(providerReturn?.dateReturned ?? '')}
        </Box>
        <Box>{'ID: ' + providerReturn?.id}</Box>
      </Box>
      <TableProductActive
        itemsRows={getRowsTableActive()}
        itemsHeader={getHeadersTable()}
        loading={loading}
      />

      <WarehouseTab
        isProviderReturn={true}
        providerId={providerReturn.providerId ?? ''}
        returnId={returnId}
        providerReturnsItems={providerReturnsItems}
        refetchProviderReturnItems={refetchProviderReturnItems}
      />
    </Box>
  );
};
