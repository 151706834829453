import React, { ReactNode } from 'react';
import { useLocalization } from '../../../../../localization';

import { HeaderCell } from '../../../../../components/TableProductActive/TableProductActive';
import { TableWarehouseStocksRowItem } from '../components/tablesComponents/TableWarehouseStocksRowItem';

import { ItemWarehouseType } from '../../../../../graphql/queries/hook/useGetWarehouse';
import { getSortProps } from '../../../../../utils/sortUtils';
import { ProviderReturnsItemsType } from '../../ProvidersReturnTab/ProviderReturnedItemsPage';
import { useGetMe } from '../../../../../graphql/queries/hook/useGetMe';

interface HookProps {
  itemsWarehouse?: ItemWarehouseType[];
  sort: string[];
  isProviderReturn: boolean;
  returnId: string;
  providerReturnsItems?: ProviderReturnsItemsType;
  refetchProviderReturnItems?: () => void;
}

interface ReturnHook {
  getHeadersTable(): Array<HeaderCell>;
  getRowsTableActive(): Array<ReactNode>;
}

export const useGetWarehouseTabTable = ({
  itemsWarehouse,
  sort,
  isProviderReturn,
  returnId,
  providerReturnsItems,
  refetchProviderReturnItems,
}: HookProps): ReturnHook => {
  const { translateLang } = useLocalization();
  const { role } = useGetMe();

  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      {
        title: translateLang('№'),
        containerProps: {
          width: '56px',
          align: 'center',
          color: '#f1f3f8',
        },
      },
      {
        title: translateLang('article'),
        containerProps: { width: '150px', color: '#f1f3f8', align: 'center' },
        sortProps: {
          ...getSortProps(sort, 'car_spare.tecdoc_articleNumber'),
        },
      },
      {
        title: translateLang('provider'),
        containerProps: { width: '240px', color: '#f1f3f8', align: 'center' },
        sortProps: { ...getSortProps(sort, 'supplier.name') },
      },
      {
        title: translateLang('productName'),
        containerProps: { width: '400px', color: '#f1f3f8', align: 'center' },
      },

      {
        title: translateLang('manufacturer'),
        containerProps: { width: '240px', color: '#f1f3f8', align: 'center' },
        sortProps: { ...getSortProps(sort, 'car_spare.car_spare_locales.brandName') },
      },
      {
        title: translateLang('quantityReceived'),
        containerProps: { width: '40px', color: '#f1f3f8', align: 'center' },
        sortProps: { ...getSortProps(sort, 'incomingQuantity') },
      },
      {
        title: translateLang('quantityReserved'),
        containerProps: { width: '40px', color: '#f1f3f8', align: 'center' },
        sortProps: { ...getSortProps(sort, 'reservedQuantity') },
      },
      {
        title: translateLang('quantityOnBalance'),
        containerProps: { width: '40px', color: '#f1f3f8', align: 'center' },
        sortProps: { ...getSortProps(sort, 'balanceQuantity') },
      },
      {
        title: translateLang('price'),
        containerProps: { width: '190px', color: '#f1f3f8', align: 'left' },
      },
      {
        title: translateLang('place'),
        containerProps: { width: '880px', color: '#f1f3f8', align: 'left' },
      },
      ...[
        isProviderReturn
          ? {
              title: translateLang('addQuantity'),
              containerProps: {
                width: '40px',
                color: '#f1f3f8',
                align: 'center' as any,
              },
            }
          : {},
      ],
      {
        title: translateLang(''),
        containerProps: { width: '40px', color: '#f1f3f8', align: 'center' },
      },
    ];
  };

  const getRowsTableActive = (): ReactNode[] => {
    if (itemsWarehouse && itemsWarehouse?.length) {
      return itemsWarehouse?.map(item => {
        return (
          <TableWarehouseStocksRowItem
            key={item.productStockId}
            stockId={item.productStockId}
            article={item.article}
            supplier={item.supplier}
            productName={item.productName}
            brandName={item.brandName}
            productStockId={item.productStockId}
            reservedQuantity={item.reservedQuantity}
            balanceQuantity={item.balanceQuantity}
            incomingQuantity={item.incomingQuantity}
            stockAddressOrder={item.stockAddressOrder}
            isProviderReturn={isProviderReturn}
            returnId={returnId}
            providerReturnsItems={providerReturnsItems}
            supplierPrice={item.supplierPrice}
            refetchProviderReturnItems={refetchProviderReturnItems}
            userRole={role}
          />
        );
      });
    }
    return [];
  };

  return {
    getHeadersTable,
    getRowsTableActive,
  };
};
